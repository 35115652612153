import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import { useIconUri } from '#hooks';

export const useStyles = (): typeof Styles => {
  const chevron = useIconUri('Chevron');
  const space = '1.125rem';

  const Styles = {
    ROOT: {
      contain: 'layout',
      maxInlineSize: '500px',

      // styling for form response message
      '& p': {
        textAlign: 'start !important',
      },
      '& span a': {
        fontWeight: '100 !important',
        color: '#292929 !important',
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        textDecorationColor: '#9C95FE',
        textUnderlineOffset: '.30rem',
        transition: 'color .2s',
        /* Firefox Only */
        '@supports (-moz-appearance: none)': {
          textUnderlineOffset: '.40rem',
        },
        '&:hover, &:focus': {
          color: '#6553FD !important',
        },
      },

    } as TSO,

    CONTAINER: {} as TSO,

    INLINE_CONTAINER: {
      display: 'flex',
      flexDirection: ['column', null, 'row'],
      '& label': {
        flexGrow: 1,
        '& .label-heading': {
          display: 'none',
        }
      }
    } as TSO,

    TEXT_INPUT: {
      display: 'flex',
      position: 'relative',
      marginBlockStart: space,
      alignItems: 'flex-start',
      flexDirection: 'column',

      '&:first-of-type': { marginBlockStart: 0 },
    } as TSO,

    INPUT: {
      variant: 'text.input',
      inlineSize: '100%',
      paddingInlineStart: '1.5ch',
      paddingInlineEnd: '1.5ch',
      paddingBlockStart: '1ex',
      paddingBlockEnd: '1ex',
      position: 'relative',
      margin: '1px',
      border: 'input.default',
      borderRadius: 2,
      cursor: 'text',
      resize: 'vertical',

      '&:required + span': {
        visibility: 'visible',
      },

      // Placeholder styling
      '&::placeholder': {
        color: 'black',
        fontSize: 1,
        fontWeight: 'body',
      },

      // Hover actions

      '&:hover:not(:disabled, :focus)': { border: 'input.valid' },

      // Focus actions

      '&:focus': {
        margin: '0px',
        outline: 'none',
        border: 'input.focus',
      },

      // Validity actions

      '&:[aria-invalid=false]:not(.--empty, :focus)': { border: 'input.valid' },

      '&[aria-invalid=true]:not(:focus)': {
        margin: '0px',
        border: 'input.error',
      },

      // Disabled actions

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    } as TSO,

    LABEL: {
      paddingBlockStart: '0px',
      paddingBlockEnd: '3px',
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
      color: 'text.secondary',
      background: 'transparent',
      transform: 'translateY(0) scale(1)',
      pointerEvents: 'none',

      // Focus actions

      'input:focus + &': { color: 'focus' },

      // Validity actions

      'input[aria-invalid=true]:not(.--empty, :focus) + &': { color: 'error' },

      // Disabled actions

      'input:disabled + &': { opacity: 0.3 },
    } as TSO,

    SELECT: {
      appearance: 'none',
      background: `url(${chevron}) calc(100% - 1.9ch) 56%/20px white no-repeat`,
      border: 'input.default',
      inlineSize: '100%',
      paddingInlineEnd: ['45px', 0],
      '&[aria-invalid=true]:not(:focus)': {
        margin: '0px',
        border: 'input.error',
      },
    } as TSO,

    MESSAGE: {
      fontSize: '0.75rem',
      paddingInlineEnd: 3,
      letterSpacing: 'comfort',
      visibility: 'hidden',
      color: 'text.secondary',

      '[aria-invalid=true] ~ &': {
        color: 'error',
        visibility: 'visible',
      },
    } as TSO,

    BUTTON: {
      backgroundColor: 'surface.highContrast',
      marginBlockStart: space,
    },

    INLINE_BUTTON: {
      backgroundColor: 'surface.highContrast',
      alignSelf: 'flex-start',
      maxHeight: '47px',
      marginBlockStart: 0,
      marginInlineStart: ['0px', '0px', space],
      minWidth: ['100%', null, 'unset'],
    },

    CONTACT: {
      gridColumn: [null, null, '2/span 8'],
      contain: 'layout',
      paddingBlockStart: space,
      paddingInlineStart: [null, '10px', 0],

      // styling for form response message
      '& p': {
        textAlign: 'start !important',
      },
      '& span a': {
        fontWeight: '100 !important',
        color: '#292929 !important',
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        textDecorationColor: '#9C95FE',
        textUnderlineOffset: '.30rem',
        transition: 'color .2s',
        /* Firefox Only */
        '@supports (-moz-appearance: none)': {
          textUnderlineOffset: '.40rem',
        },
        '&:hover, &:focus': {
          color: '#6553FD !important',
        },
      },

      fieldset: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        label: {
          flexBasis: '100%',
          '&:first-of-type': { marginBlockStart: space },
        },

        'label:nth-of-type(-n+5)': {
          flexBasis: ['100%', '45%'],
          marginInlineEnd: 1,
        },
      },
    } as TSO,

    NAME_CONTAINER: {
      marginBlockEnd: 3,
      '& span': {
        whiteSpace: 'nowrap',
      },
    } as TSO,

    CLEAR_BTN: {
      fontSize: 0,
      letterSpacing: 'comfort',
      background: 'none',
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textDecorationColor: 'accent.primary',
      textUnderlineOffset: '.30rem',
      transition: 'color .2s',
      height: '1.5rem',
      outline: 0,

      '&:hover, &:focus': {
        color: 'lilac.6',
      },
    } as TSO,

    ASIDE: {
      paddingInlineEnd: [0, 1, 0],
      marginBlockStart: ['20%', '50%'],
      gridColumn: [null, '11 / span 6'],
    },
    SMALL_TEXT: {
      maxInlineSize: '500px',
      fontSize: 0,
      marginBlockStart: 3,
      textAlign: ['center', 'left'],
    } as TSO,
    ERROR: {
      color: 'error',
    },
    CAPTCHA: {
      flexBasis: '100%',
      marginBlockStart: '50px',
    },
    CHECKMARK: {
      position: 'relative',
      display: 'inline-block',
      paddingInlineStart: '45px',
      ' input': {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
      },

      /* Create a custom checkbox */
      '.checkmark': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '25px',
        width: '25px',
        backgroundColor: '#eee',
      },

      /* On mouse-over, add a grey background color */
      '&:hover input ~ .checkmark': {
        backgroundColor: '#ccc',
      },

      /* When the checkbox is checked, add a blue background */
      'input:checked ~ .checkmark': {
        backgroundColor: 'primary',
      },

      /* Create the checkmark/indicator (hidden when not checked) */
      '.checkmark:after': {
        content: '""',
        position: 'absolute',
        display: 'none',
      },

      /* Show the checkmark when checked */
      'input:checked ~ .checkmark:after': {
        display: 'block',
      },

      /* Style the checkmark/indicator */
      '.checkmark:after ': {
        left: '10px',
        top: '6px',
        width: '5px',
        height: '10px',
        border: 'solid white',
        borderWidth: '0 2px 2px 0',
        transform: 'rotate(45deg)',
      },
    } as TSO,
  };

  return Styles;
};
