import { useEffect, useState } from 'react';
import { H5 } from '#ui';
import { propsValidator, createId } from '#utils';
import { InputTypeMap } from '#constants';
import { useStyles } from '../form.styles';

import type { FC, ChangeEvent } from 'react';
import type { FormInputProps as Props } from '../types';

const SelectOption: FC<Props> = ({
  label,
  required,
  dropdownOptions,
  bindInputState,
  ...entry
}) => {
  const validProp = propsValidator(entry, { label });

  if (!validProp) return null;

  const [value, setValue] = useState<string>(label!);
  const [valid, setValid] = useState(true);

  const Styles = useStyles();
  const id: string = createId(label!);
  const options: false | string[] =
    !!dropdownOptions?.length && dropdownOptions;

  const updateValue = ({ target }: ChangeEvent<HTMLSelectElement>): void => {
    setValid(target.checkValidity());
    setValue(target.value);
    window.sessionStorage.setItem(id, target.value);
  };

  useEffect(() => {
    const storedValue = window.sessionStorage.getItem(id);

    if (storedValue) setValue(storedValue);

    bindInputState(id, setValid);
  }, []);

  return (
    <label sx={Styles.TEXT_INPUT}>
      <H5 as="span" sx={Styles.LABEL} className="label-heading">
        {label}
      </H5>
      <select
        sx={{ ...Styles.INPUT, ...Styles.SELECT }}
        name={id}
        required={!!required}
        autoComplete={InputTypeMap.Select.autoComplete}
        aria-describedby={`error-${id}`}
        aria-invalid={!valid}
        value={value}
        onChange={updateValue}
      >
        <option value="">Select {label}</option>
        {options &&
          options.map((option) => (
            <option key={createId(option)} value={option}>
              {option}
            </option>
          ))}
      </select>
      {!!required && (
        <span sx={Styles.MESSAGE}>
          <span>This field is required.</span>
        </span>
      )}
      <span sx={Styles.MESSAGE} id={`error-${id}`} aria-live="assertive">
        {!valid ? InputTypeMap.Select.error : ''}
      </span>
    </label>
  );
};

export default SelectOption;
