import {
  clearNamedCookie,
  createId,
  getNamedCookie,
  setCookie,
} from '@lib/utils';

import type { FormEvent, RefObject } from 'react';
import type { ContentfulFormInput } from '#types';
import type { Data, CookieFields } from './types';

export const checkFields = (
  inputsCollection: ContentfulFormInput[]
): CookieFields => {
  const inputsList = inputsCollection.map((item) => {
    const id: string = createId(item.label!);
    return id;
  });
  let fieldsMatched = true;
  let firstname;
  let lastname;

  // check for gatedFormCookie
  const gatedCookieString = getNamedCookie('gatedFormCookie');
  if (!gatedCookieString) fieldsMatched = false;

  const gatedCookie: { [key: string]: string }[] | null = gatedCookieString
    ? JSON.parse(gatedCookieString)
    : null;

  if (gatedCookie) {
    Object.entries(inputsList).map((item) => {
      // make sure cookie includes data for every field in the form
      if (!gatedCookie.map((field) => field.name === item[1])) {
        fieldsMatched = false;
      }
    });
  }

  if (gatedCookie && fieldsMatched) {
    // add cookie values to session storage for form and submit function access
    gatedCookie.map((item) => {
      window.sessionStorage.setItem(item.name, item.value);
    });
    //return names for display
    gatedCookie.map((item) => {
      if (item.name === 'firstname') firstname = item.value;
    });
    gatedCookie.map((item) => {
      if (item.name === 'lastname') lastname = item.value;
    });
  }

  return { fieldsMatched, firstname, lastname };
};

export const clearFields = (inputsCollection: ContentfulFormInput[]): void => {
  clearNamedCookie('gatedFormCookie');
  const inputsList = inputsCollection.map((item) => {
    const id: string = createId(item.label!);
    return id;
  });

  Object.entries(inputsList).map((item) => {
    window.sessionStorage.removeItem(item[1]);
  });
};

export const submitForm = (
  e: FormEvent<HTMLFormElement>,
  formId: string,
  ref: RefObject<HTMLFormElement> | null,
  errorRef: RefObject<HTMLParagraphElement> | null,
  token?: string | null,
  setFormCookie?: boolean,
  setEmailCookie?: boolean
): void => {
  const formData: Data = {
    fields: [],
    formId,
    token,
  };

  // form data is being saved to SessionStorage for access
  Object.entries(window.sessionStorage).map((item) => {
    // make sure the data in session storage is actually the data for this form
    if (Object.entries(e.target).some((field) => field[1].name === item[0])) {
      // push it into the data object's fields array in the format that hubspot wants
      formData.fields.push({
        objectTypeId: '0-1',
        name: item[0],
        value: item[1],
      });
    }
  });

  formData.submittedAt = Date.now().toString();

  fetch(`/api/cms/form`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((response) => {
      const { data } = response;
      if (data.redirectUri && setFormCookie) {
        setCookie('gatedFormCookie', JSON.stringify(formData.fields));
        window.location.href = data.redirectUri;
      }

      // if form submitted successfully & saveInlineEmail is true -> save email cookie
      if (data && data.status !== 'error' && setEmailCookie) {
        const emailAddress = window.sessionStorage.getItem('email');
        if (emailAddress) setCookie('user_email', emailAddress, 7);
      }

      if (data.redirectUri) {
        window.location.href = data.redirectUri;
      }
      if (data.inlineMessage) {
        if (ref !== null && ref.current !== null) {
          ref.current.innerHTML = `${data.inlineMessage}`;
        }
      }
      if (data.status === 'error') {
        if (errorRef !== null && errorRef.current !== null) {
          errorRef.current.innerHTML = `${data.message}:
          ${
            data.errors
              ? data.errors[0].message
              : 'Please reload the page and try again'
          }`;
        }
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    });
};
