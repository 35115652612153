import InlineForm from './InlineForm/InlineForm';
import FormSection from './FormSection/FormSection';
import ComponentForm from './ComponentForm/ComponentForm';
import { FormVariant } from '#constants';
import { getSpacing } from '@lib/utils';


import type { FC, ReactElement } from 'react';
import type { FormProps as Props } from './types';

const Form: FC<Props> = ({ variant, ...props }) => {
  let formContent: ReactElement;

  // handle deprecated variant names
  if (variant === FormVariant.CONTACT) {
    variant = FormVariant.SECTION;
  }
  if (variant === FormVariant.GATED_CONTENT) {
    variant = FormVariant.COMPONENT;
  }
  switch (variant) {
    case FormVariant.INLINE:
      formContent = <InlineForm {...props} />;
      break;
    case FormVariant.SECTION:
      formContent = <FormSection {...props} />;
      break;
    case FormVariant.COMPONENT:
      formContent = <ComponentForm {...props} />;
      break;
    default:
      throw Error(`Variant type ${variant} is not a Form variant.`);
  }

  return (
    <section sx={{ marginBlockStart: [getSpacing(props.order), null, 0] }}>


      {formContent}
    </section>
  );
};

export default Form;
