import { useEffect, useRef, useState } from 'react';
import SelectOption from '@components/Form/Inputs/SelectOption';
import TextInput from '@components/Form/Inputs/TextInput';
import { submitForm, checkFields, clearFields } from '../sharedUtils';
import { Button, Link, H4 } from '#ui';
import { FormInputVariant, HUBSPOT_PORTAL_ID } from '#constants';
import { sendEvent } from '#utils';
import { useStyles } from '../form.styles';

import type { FC, InvalidEvent } from 'react';
import type { ContentfulFormInput } from '#types';
import type {
  ComponentFormProps as Props,
  InputState,
  BindInputState,
} from '../types';

const ComponentForm: FC<Props> = ({
  title,
  hideTitle,
  inputsCollection,
  submitButtonText,
  sys,
  analyticsEvent,
  formHandler,
  embedForm,
  setEmailCookie,
}) => {
  const $inputState = useRef<InputState>({});
  const $formState = useRef<HTMLFormElement>(null);
  const $error = useRef<HTMLParagraphElement>(null);
  const Styles = useStyles();
  const inputs: false | ContentfulFormInput[] =
    !!inputsCollection.items.length && inputsCollection.items;

  const invalidateForm = (e: InvalidEvent<HTMLFormElement>): void => {
    e.preventDefault();
    $inputState.current[e.target.name](false);
  };

  const bindInputState: BindInputState = (id, state) => {
    $inputState.current = { ...$inputState.current, [id]: state };
  };

  const [firstname, setFirstname] = useState<string | null>(null);
  const [lastname, setLastname] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [path, setPath] = useState<string | null>(null);

  // values for form submit
  const token = null;  // recapcha token only used in Form Section
  const setFormCookie = true;  // form cookie set here

  useEffect(() => {
    setPath(window.location.pathname);
    if (embedForm) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        // eslint-disable-next-line
        // @ts-ignore
        if (window.hbspt) {
          // eslint-disable-next-line
          // @ts-ignore
          window.hbspt.forms.create({
            portalId: HUBSPOT_PORTAL_ID,
            formId: formHandler,
            target: '#hubspotForm',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const { fieldsMatched, firstname, lastname } = checkFields(
      inputsCollection.items
    );
    setShowForm(!fieldsMatched);
    setRefresh((prev) => !prev);

    if (firstname && lastname) {
      setFirstname(firstname);
      setLastname(lastname);
    }
  }, []);

  const clearValues = () => {
    clearFields(inputsCollection.items);
    setRefresh((prev) => !prev);
    setShowForm(true);
  };

  return (
    <>
      {!!title && !hideTitle && (
        <H4 sx={{gridColumn: [null, null, '2/span 8'], marginInlineStart: [null, '10px',null] }}>{title}</H4>
      )}
      {!embedForm && (
        <>
          <form
            sx={Styles.ROOT}
            title={title || undefined}
            onSubmit={(e) => {
              e.preventDefault();
              analyticsEvent
                ? sendEvent(analyticsEvent, submitButtonText, path)
                : null;
              submitForm(e, sys.id || '', $formState, $error, token, setFormCookie, !!setEmailCookie);
            }}
            onInvalid={invalidateForm}
            method="post"
            ref={$formState}
          >
            <fieldset sx={Styles.CONTAINER}>
              <div
                sx={{
                  display: showForm ? 'none' : 'block',
                  ...Styles.NAME_CONTAINER,
                }}
              >
                {firstname && lastname ? (
                  <h2>
                    {' '}
                    Welcome back,{' '}
                    <span>
                      {firstname} {lastname}!
                    </span>
                  </h2>
                ) : (
                  <h2>Welcome back!</h2>
                )}
                <button
                  type="button"
                  sx={Styles.CLEAR_BTN}
                  onClick={() => clearValues()}
                >
                  Different user?
                </button>
              </div>
              <div sx={{ display: showForm ? 'block' : 'none' }}>
                <legend className="global-sr">{title}</legend>
                {inputs &&
                  inputs.map((item) => {
                    switch (item.variant) {
                      case FormInputVariant.SelectOption:
                        return (
                          <SelectOption
                            key={item.sys.id}
                            bindInputState={bindInputState}
                            {...item}
                          />
                        );
                      case FormInputVariant.TextInput:
                        return (
                          <TextInput
                            key={item.sys.id}
                            bindInputState={bindInputState}
                            {...item}
                            refresh={refresh}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
              </div>
              <Button type="submit" sx={Styles.BUTTON}>
                {submitButtonText || 'Submit'}
              </Button>
              <p ref={$error} sx={Styles.ERROR}></p>
            </fieldset>
          </form>
          <p sx={Styles.SMALL_TEXT}>
            By submitting the form, you agree to our{' '}
            <Link href="/legal/terms">Terms of Service</Link> and acknowledge
            our <Link href="/legal/privacy">Privacy Policy</Link>.
          </p>
        </>
      )}
      {embedForm && <div id="hubspotForm" sx={Styles.CONTACT}></div>}
    </>
  );
};

export default ComponentForm;
