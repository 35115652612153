import { useState, useEffect, useRef } from 'react';
import SelectOption from '@components/Form/Inputs/SelectOption';
import TextInput from '@components/Form/Inputs/TextInput';
import { Button, Grid, Link, H4 } from '#ui';
import { submitForm } from '../sharedUtils';
import { FormInputVariant } from '#constants';
import { getSpacing, sendEvent } from '#utils';
import { useStyles } from '../form.styles';
import AppButton from '@components/AppButton';
import MarkdownText from '@components/MarkdownText';
import ReCAPTCHA from 'react-google-recaptcha';
import { HUBSPOT_PORTAL_ID } from '#constants';

import type { FC, InvalidEvent } from 'react';
import type { ContentfulFormInput } from '#types';
import type {
  FormSectionProps as Props,
  InputState,
  BindInputState,
} from '../types';

const FormSection: FC<Props> = ({
  title,
  hideTitle,
  inputsCollection,
  submitButtonText,
  helpText,
  helpCta,
  sys,
  analyticsEvent,
  formHandler,
  embedForm,
  order,
  setEmailCookie,
}) => {
  const $inputState = useRef<InputState>({});
  const $formState = useRef<HTMLFormElement>(null);
  const $captcha = useRef<ReCAPTCHA>(null);
  const $error = useRef<HTMLParagraphElement>(null);
  const Styles = useStyles();
  const inputs: false | ContentfulFormInput[] =
    !!inputsCollection.items.length && inputsCollection.items;
  const [path, setPath] = useState<string | null>(null);

  // values for form submit
  const setFormCookie = false;  // form cookie only set in Component Form

  useEffect(() => {
    setPath(window.location.pathname);
    if (embedForm) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        // eslint-disable-next-line
        // @ts-ignore
        if (window.hbspt) {
          // eslint-disable-next-line
          // @ts-ignore
          window.hbspt.forms.create({
            portalId: HUBSPOT_PORTAL_ID,
            formId: formHandler,
            target: '#hubspotForm',
          });
        }
      });
    }
  }, []);

  const invalidateForm = (e: InvalidEvent<HTMLFormElement>): void => {
    e.preventDefault();
    $inputState.current[e.target.name](false);
  };

  const bindInputState: BindInputState = (id, state) => {
    $inputState.current = { ...$inputState.current, [id]: state };
  };

  const getToken = async (): Promise<string> => {
    // we get a token from recaptcha to pass to the server for verification
    const token = await $captcha.current!.executeAsync();
    return token || '';
  };

  return (
    <Grid sx={{marginBlockStart: getSpacing(order)}}>
      {!!title && !hideTitle && (
        <H4 sx={{ gridColumn: [null, null, '2/span 8'], marginInlineStart: [null, '10px',null] }}>{title}</H4>
      )}
      {!embedForm && (
        <form
          sx={Styles.CONTACT}
          title={title || undefined}
          data-action="submit"
          onSubmit={async (e) => {
            e.preventDefault();
            const token = await getToken();
            analyticsEvent
              ? sendEvent(analyticsEvent, submitButtonText, path)
              : null;
            submitForm(e, sys.id || '', $formState, $error, token, setFormCookie, !!setEmailCookie);
          }}
          onInvalid={invalidateForm}
          ref={$formState}
        >
          <fieldset sx={Styles.CONTAINER}>
            {inputs &&
              inputs.map((item) => {
                switch (item.variant) {
                  case FormInputVariant.SelectOption:
                    return (
                      <SelectOption
                        key={item.sys.id}
                        bindInputState={bindInputState}
                        {...item}
                      />
                    );
                  case FormInputVariant.TextInput:
                    return (
                      <TextInput
                        key={item.sys.id}
                        bindInputState={bindInputState}
                        {...item}
                      />
                    );
                  default:
                    return null;
                }
              })}
            <div sx={{flexBasis:'100%', width: '0'}}></div>
            <Button
              sx={Styles.BUTTON}
              type="submit"
              role="button"
            >
              {submitButtonText || 'Submit'}
            </Button>
            <p sx={Styles.SMALL_TEXT}>
              By submitting the form, you agree to our{' '}
              <Link href="/legal/terms">Terms of Service</Link> and acknowledge
              our <Link href="/legal/privacy">Privacy Policy</Link>.
            </p>
            <p ref={$error} sx={Styles.ERROR}></p>
            {/* docs: https://github.com/dozoisch/react-google-recaptcha */}
            <ReCAPTCHA
              ref={$captcha}
              size="invisible"
              sitekey={`${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
              badge="inline"
              sx={Styles.CAPTCHA}
            />
          </fieldset>
        </form>
      )}
      {embedForm && <div id="hubspotForm" sx={Styles.CONTACT}></div>}
      {(!!helpText || !!helpCta) && (
        <aside sx={Styles.ASIDE} aria-label="form help">
          {!!helpText && (
            <MarkdownText styles={{ paddingInlineStart: 0 }} >
              {helpText}
            </MarkdownText>
          )}
          {!!helpCta && (
            <AppButton
              componentStyle={{
                inlineSize: ['100%', 'fit-content'],
                backgroundColor: 'surface.highContrast',
                marginBlockStart: 2,
              }}
              {...helpCta}
            />
          )}
        </aside>
      )}
    </Grid>
  );
};

export default FormSection;
