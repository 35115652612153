import { useEffect, useState } from 'react';
import { H5 } from '#ui';
import { propsValidator, createId } from '#utils';
import { InputTypeMap, COUNTRIES } from '#constants';
import { useStyles } from '../form.styles';

import type { FC, ChangeEvent } from 'react';
import type { FormInputProps as Props } from '../types';

const CountrySelect: FC<Props> = ({
  label,
  required,
  bindInputState,
  ...entry
}) => {
  const validProp = propsValidator(entry, { label });

  if (!validProp) return null;

  const [value, setValue] = useState<string>('');
  const [checkValue, setCheckValue] = useState<string>('');

  const [valid, setValid] = useState(true);

  const Styles = useStyles();
  const id: string = createId(label!);
  const options: Record<string, string> = COUNTRIES;

  const updateValue = ({ target }: ChangeEvent<HTMLSelectElement>): void => {
    setValid(target.checkValidity());
    setValue(target.value);
    window.sessionStorage.setItem(id, target.value);
    if(target.value === 'US' || target.value === ''){
      window.sessionStorage.removeItem('rev_email_opt_in__c')
    }else {
      window.sessionStorage.setItem('rev_email_opt_in__c', 'false');
      setCheckValue('false');
    }
  };

  const checkInput = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setValid(target.checkValidity());
    if (target.checked) {
      window.sessionStorage.setItem('rev_email_opt_in__c', 'true');
      setCheckValue('true');
    } else {
      window.sessionStorage.setItem('rev_email_opt_in__c', 'false');
      setCheckValue('false');
    }
  };

  useEffect(() => {
    const storedValue = window.sessionStorage.getItem(id);

    if (storedValue) setValue(storedValue);

    bindInputState(id, setValid);
  }, []);

  return (
    <>
      <label sx={Styles.TEXT_INPUT}>
        <H5 as="span" sx={Styles.LABEL} className="label-heading">
          {label}
        </H5>
        <select
          sx={{ ...Styles.INPUT, ...Styles.SELECT }}
          name={id}
          required={!!required}
          autoComplete={InputTypeMap.Select.autoComplete}
          aria-describedby={`error-${id}`}
          aria-invalid={!valid}
          value={value}
          onChange={updateValue}
        >
          <option value="">Select {label}</option>
          {options &&
            Object.keys(options).map((key) => (
              <option key={createId(options[key])} value={key}>
                {options[key]}
              </option>
            ))}
        </select>
        {!!required && (
          <span sx={Styles.MESSAGE}>
            <span>This field is required.</span>
          </span>
        )}
        <span sx={Styles.MESSAGE} id={`error-${id}`} aria-live="assertive">
          {!valid ? InputTypeMap['Country Name'].error : ''}
        </span>
        {value !== '' && value !== 'US' && (
          <div>
            <label htmlFor="rev_email_opt_in__c" sx={Styles.CHECKMARK}>
              I&apos;d like to receive emails from Rev to stay up to date on
              products, services, and promotions.
              <input
                type="checkbox"
                id="rev_email_opt_in__c"
                name="rev_email_opt_in__c"
                value={checkValue}
                onChange={checkInput}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
      </label>
    </>
  );
};

export default CountrySelect;
