import { useState, useEffect } from 'react';
import { propsValidator, createId } from '#utils';
import { InputTypeMap } from '#constants';
import { useStyles } from '../form.styles';
import CountrySelect from './CountrySelect';
import { H5 } from '#ui';

import type { FC, ChangeEvent } from 'react';
import type { FormInputProps as Props } from '../types';

const TextInput: FC<Props> = ({
  label,
  required,
  type: kind,
  bindInputState,
  longText,
  refresh,
  ...entry
}) => {
  const validProp = propsValidator(entry, { label });

  if (!validProp) return null;

  const [value, setValue] = useState(
    InputTypeMap[kind].type === 'tel' ? '+1' : ''
  );
  const [empty, setEmpty] = useState(true);
  const [valid, setValid] = useState(true);

  const Styles = useStyles();
  const id: string = createId(label!);

  const checkInput = ({
    target,
  }:
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLTextAreaElement>): void => {
    if (target.value === '') setEmpty(true);
    else setEmpty(false);

    setValid(target.checkValidity());

    // Save form info for the user during this session
    window.sessionStorage.setItem(id, target.value);
    setValue(target.value);
  };

  useEffect(() => {
    // Restore form info if available
    const storedValue = window.sessionStorage.getItem(id);

    if (!storedValue) {
      setValue('');
      setEmpty(true);
      setValid(true);
    } else {
      setValue(storedValue);
      setEmpty(false);
      setValid(true);
    }
    // only bind input state if not the country name exception
    if (kind !== 'Country Name'){
      bindInputState(id, setValid);
    }

  }, [refresh]);

  // exception for Country Input
  if (kind === 'Country Name') {
    return (
      <CountrySelect
        label={label}
        bindInputState={bindInputState}
        required={required}
        type={kind}
        {...entry}
      />
    );
  }

  return (
    <label sx={Styles.TEXT_INPUT}>
      <H5 as="span" sx={Styles.LABEL} className="label-heading">
        {label}
      </H5>
      {!longText && (
        <input
          placeholder={label || undefined}
          sx={Styles.INPUT}
          className={empty ? '--empty' : ''}
          name={id}
          type={InputTypeMap[kind].type}
          required={!!required}
          autoComplete={InputTypeMap[kind].autoComplete}
          disabled={label === 'Disabled'} // Only for design QA, will be removed
          aria-describedby={`error-${id}`}
          aria-invalid={!valid}
          defaultValue={value}
          onChange={(e) => checkInput(e)}
          pattern={
            InputTypeMap[kind].type === 'tel'
              ? '\\+(?:[0-9] ?){6,14}[0-9]$'
              : undefined
          }
        />
      )}
      {longText && (
        <textarea
          placeholder={label || undefined}
          sx={Styles.INPUT}
          className={empty ? '--empty' : ''}
          name={id}
          required={!!required}
          autoComplete={InputTypeMap[kind].autoComplete}
          disabled={label === 'Disabled'} // Only for design QA, will be removed
          aria-describedby={`error-${id}`}
          aria-invalid={!valid}
          defaultValue={value}
          onChange={(e) => checkInput(e)}
        />
      )}
      {!!required && (
        <span sx={Styles.MESSAGE}>
          <span>This field is required.</span>
        </span>
      )}
      <span sx={Styles.MESSAGE} id={`error-${id}`} aria-live="assertive">
        {!valid ? InputTypeMap[kind].error : ''}
      </span>
    </label>
  );
};

export default TextInput;
