import { useState, useEffect, useRef } from 'react';
import SelectOption from '@components/Form/Inputs/SelectOption';
import TextInput from '@components/Form/Inputs/TextInput';
import { Button, H4, Link } from '#ui';
import { FormInputVariant } from '#constants';
import { sendEvent } from '#utils';
import { useStyles } from '../form.styles';
import { submitForm } from '../sharedUtils';
import type { FC, InvalidEvent } from 'react';
import type { ContentfulFormInput } from '#types';
import type {
  InlineFormProps as Props,
  InputState,
  BindInputState,
} from '../types';

const InlineForm: FC<Props> = ({
  formHandler,
  title,
  hideTitle,
  inputsCollection,
  submitButtonText,
  sys,
  analyticsEvent,
  setEmailCookie,
  helpText,
}) => {
  const $inputState = useRef<InputState>({});
  const $formState = useRef<HTMLFormElement>(null);
  const $error = useRef<HTMLParagraphElement>(null);
  const Styles = useStyles();
  const inputs: false | ContentfulFormInput[] =
    !!inputsCollection.items.length && inputsCollection.items;
  const [path, setPath] = useState<string | null>(null);
  const singleEntryForm = inputsCollection.items.length === 1;

  // values for form submit
  const token = null;  // recapcha token only used in Form Section
  const setFormCookie = false;  // form cookie only set in Component Form

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  const invalidateForm = (e: InvalidEvent<HTMLFormElement>): void => {
    e.preventDefault();
    $inputState.current[e.target.name](false);
  };

  const bindInputState: BindInputState = (id, state) => {
    $inputState.current = { ...$inputState.current, [id]: state };
  };

  return (
    <>
      {!!title && !hideTitle && (
        <H4 sx={{ gridColumn: [null, null, '2/span 8'], marginInlineStart: [null, '10px',null] }}>{title}</H4>
      )}
      <form
        sx={Styles.ROOT}
        action={formHandler || ''}
        title={title || undefined}
        onSubmit={(e) => {
          e.preventDefault();
          analyticsEvent
            ? sendEvent(analyticsEvent, submitButtonText, path)
            : null;
          submitForm(e, sys.id || '', $formState, $error, token, setFormCookie, !!setEmailCookie);
        }}
        onInvalid={invalidateForm}
        ref={$formState}
      >
        <fieldset sx={singleEntryForm ? Styles.INLINE_CONTAINER : Styles.CONTAINER}>
          <legend className="global-sr">{title}</legend>
          {inputs &&
            inputs.map((item) => {
              switch (item.variant) {
                case FormInputVariant.SelectOption:
                  return (
                    <SelectOption
                      key={item.sys.id}
                      bindInputState={bindInputState}
                      {...item}
                    />
                  );
                case FormInputVariant.TextInput:
                  return (
                    <TextInput
                      key={item.sys.id}
                      bindInputState={bindInputState}
                      {...item}
                    />
                  );
                default:
                  return null;
              }
            })}
          <Button sx={singleEntryForm ? Styles.INLINE_BUTTON : Styles.BUTTON} type="submit">
            {submitButtonText || 'Submit'}
          </Button>
        </fieldset>
      </form>
      {helpText && <p sx={Styles.SMALL_TEXT}>{helpText}</p>}
      {!singleEntryForm && 
        <p sx={Styles.SMALL_TEXT}>
          By submitting the form, you agree to our{' '}
          <Link href="/legal/terms">Terms of Service</Link> <br/> and acknowledge our{' '}
          <Link href="/legal/privacy">Privacy Policy</Link>.
        </p>
      }
      <p ref={$error} sx={Styles.ERROR}></p>
    </>
  );
};

export default InlineForm;
